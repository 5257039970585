.main-container.homePage {
    margin-top: 85px
}

.homePage {


    .MuiTableBody-root span.material-icons {
        margin: 0 5px !important
    }

    .MuiTableBody-root span[title=" Export to Excel"] {
        position: relative;
        top: -6px
    }

    .MuiTableBody-root span[title=" Export to Excel"] .icnExport {
        font-size: 27px
    }

    .MuiTableBody-root tr td:nth-child(6) span:first-child {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .analytics-section .MuiPaper-rounded {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        background-color: rgba(255, 255, 255, .2) !important;
        color: #fff;
        padding: 10px 20px
    }

    .icn {
        margin-right: 10px;
        border-right: 1px solid #fff;
        padding-right: 20px
    }

    .analytics span:nth-child(2) {
        font-size: 32px;
        font-weight: bold
    }

    .analytics span:nth-child(3) {
        font-size: 12px;
        width: 50px;
        display: inline-block;
        text-align: left;
        padding-left: 10px;
        position: relative;
        top: 3px
    }

    .MuiToolbar-gutters {
        position: relative;
        padding-left: 24px !important
    }

    .MuiToolbar-gutters .MTableToolbar-searchField-10,
    .MuiToolbar-gutters .MuiTextField-root {
        position: relative;
        right: 250px;
    }

    .MuiToolbar-gutters .MuiButton-containedPrimary {
        position: absolute;
        right: 20px;
        top: 15px;
        background: #5A047F
    }

    .MuiToolbar-gutters .gridHeading {
        font-weight: bold;
        font-size: 18px;
        text-transform: capitalize;
        color: #5A047F
    }

    .MuiTable-root {
        margin: 0 20px;
        width: 97% !important
    }

    .analytics-section {
        overflow: hidden;
        margin-bottom: 20px
    }

    .icnR {
        margin-right: 10px
    }

    .MuiToolbar-regular {
        min-height: 64px !important
    }

    .MuiTableBody-root select {
        border-radius: 5px;
        padding: 3px 5px
    }

    .MuiTableCell-sizeSmall {
        padding: 5px
    }
}

.registrationDiv {
    padding: 2rem 0;
    text-align: left;

    .accordianDiv .col-md-12 {
        padding: 0;

        .row .col-md-12 {
            padding: 0 15px;

            label.active {
                left: 0;
            }
        }
    }

    .control-customSwitch {
        text-align: right;

        .react-switch-bg {
            line-height: 37px;
            text-align: center;
        }
    }

    .fi-file-uploader-wrapper {
        margin-right: 0;

        .file-validation {
            background: transparent;
            color: #ff3547;
            font-size: 12px;
        }

        .empty-files {
            text-align: left;
        }
    }

    h3.form-title,
    h1 {
        border-bottom: 1px solid #5A047F;
        font-size: 1.5rem;
    }

    .form-display-info {
        font-size: .75rem;
        float: right;
    }

    .form-actions {
        text-align: center;

        button[type="button"],
        button[type="submit"] {
            color: #fff;
            background-color: #0069d9 !important;
            text-transform: capitalize;
            border: 1px solid #0062cc;
            padding: .5rem 1rem;
            border-radius: 4px;
            display: inline-block;
        }
    }

    .editor-wrapper {
        border: 1px solid #F1F1F1;
        border-top: 0;
        min-height: 250px;
    }

    .rdw-editor-toolbar {
        border-left: 0;
        border-right: 0
    }

    .control-country {
        .md-form {
            margin: 0;
        }
    }
}

.App {
    .centerThis {
        text-align: center;
    }
}


.dashboardDetail {display:inline-block; width:100%;
    .MuiPaper-root {padding:10px}
    .selectDiv {margin-bottom:.5rem;}
.dashboardHead {margin-top:.5rem; margin-bottom:1rem; float:left;
    h4.MuiTypography-root {font-size:20px; color:#000; font-weight:bold; display:inline-block;}
    .optionClick {display:inline-block; margin-left:1rem; position:relative; top:-.2rem;
        .MuiSvgIcon-root {margin-right:.5rem; cursor:pointer;}
        .MuiSvgIcon-root:hover, .MuiSvgIcon-root.active {color:#0062cc;}
        .MuiSvgIcon-root:first-child {font-size:16px;}
    }
}
.accountOverviewSec, .activityOverviewSec, .calenderOverviewSec, .leadOverviewSec, .persistanceyOverviewSec, .campaignLeadOverviewSec {padding-bottom:1rem; position:relative;
    h5.MuiTypography-root {font-size:14px; color:#000; font-weight:bold; float:left; margin-right:.7rem;
        .MuiSvgIcon-root {font-size:12px;}
    }
    .bdmList{
        display: block;
        // padding: 10px;
        border-bottom: 1px solid #ccc;
        color: #000;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 12px;
        padding: 2px;
    }
    .viewAll {color:#00427E; font-size:11px; font-weight:bold; cursor:pointer; display:inline-block;}
    .selectBx {border:1px solid #ccc; border-radius:4px; padding:0;
        .MuiSelect-root {padding:4px 24px 4px 5px; text-align:left; font-size:10px; font-weight:bold;}
        .MuiInput-underline:before {display:none;}
    }
    .selectBxFull {display:block; margin-right:.5rem;
        .MuiInputBase-root {width:100%;}
    }
    .zoomIcon {position:absolute; bottom:-.5rem; right:-.5rem; cursor:pointer; font-size:16px;}
} 
.leadOverviewSec, .persistanceyOverviewSec {
    .zoomIcon {top:2rem; right:7.5rem}
}
.campaignLeadOverviewSec {
    .zoomIcon {top:.2rem; right:1.5rem}
}
.padTp0 {padding-top:0 !important;}
.accountOverviewSec {padding-bottom:0;
.profileDiv {background: transparent linear-gradient(180deg, #FFFFFF 0%, #BDD2E5 100%) 0% 0% no-repeat; border:1px solid rgba(204, 204, 204, 0.5); width:107vh; margin:-10px -10px 10px; border-radius:5px 5px 0 0; padding:15px 25px; border-bottom:1px dotted #ccc;
    
    .dpDiv {width:6rem; height:6rem; color:#fff; background:#00427E; float:left; border-radius:10px; position:relative; font-size:30px; font-weight:bold; line-height:6rem; text-align:center; margin-right:1rem;
        .circle {width:10px; height:10px; border:1px solid #fff; background:#08C400; position:absolute; top:.5rem; right:.5rem; border-radius:50px;}
    }
    .welText {font-size:12px; color:#00427E; font-weight:bold; margin-top:.3rem;}
    h6.MuiTypography-root {font-size:16px; font-weight:bold; color:#00427E; margin:0; padding:0;}
    .loggDiv {font-size:10px; color:#000; font-weight:bold; margin:-.25rem 0 .25rem; display:block;}
    .profSetting, .lastSeenDiv {font-size:12px; font-weight:bold; color:#000; cursor:pointer;
        .MuiSvgIcon-root {font-size:12px; position:relative; top:-2px;}
    }
    .lastSeenDiv {margin:-.35rem 0 0; padding:0; display:block;}
    .statusDiv {font-size:14px; font-weight:bold; margin-bottom:.75rem; color:#000; margin-left:7rem;}
    .socialIconABH {border-top:1px solid #ccc; padding-top:.75rem;
        span {min-width:70px; display:inline-block; text-align:center;
            svg {background:#fff; border:1px solid #DFE9F3; border-radius:50px; padding:8px 5px; font-size:40px; color:#00427E;}
            em {font-style:normal; color:#00427E; display:block; font-size:10px; margin-top:.5rem;}
        }
    }
}



.dtDiv, .expectDiv, .payDiv {font-size:11px; color:#000; font-weight:bold; min-height:22px;}
.expectDiv {color:#0062cc;}
.MuiPaper-root {position:relative; box-shadow:none; margin:4px; color:#fff; border-radius:10px; padding:15px;
    .arrowIcon {position:absolute; top:.5rem; right:.5rem; font-size:16px; cursor:pointer;}
    .num {font-size:30px; font-weight:bold;}
    .numText {font-size:12px;
        .MuiSvgIcon-root {font-size:14px;}
    }
}
    .navyBlueBG {background:#1B447D;}
    .blueBG {background:#0093DE;}
    .orangeBG {background:#DD7528;}
    .redBG {background:#BF383E;}
}

.greyView {
   .greyViewInner {cursor: pointer; position:relative; background: transparent linear-gradient(180deg, #F3F4F7 0%, #CAD9E6 100%) 0% 0% no-repeat padding-box; border:1px solid rgba(121, 149, 191, 0.4); border-radius:10px; margin:4px; padding:15px 10px 5px; text-align:center;
    .titleActivity {font-size:10px; font-weight:bold; color:#000; border-bottom:1px dotted #000; display:inline-block; padding-bottom:.35rem; margin-top:.5rem; min-height:30px;}
    .MuiSvgIcon-root {clear:both;}
    .MuiSvgIcon-root.navyBlueBG {color:#1B447D;}
    .MuiSvgIcon-root.blueBG {color:#0093DE;}
    .MuiSvgIcon-root.orangeBG {color:#DD7528;}
    .MuiSvgIcon-root.redBG {color:#BF383E;}
    .numDiv {font-size:13px;  color:#000;
        span {font-size:22px; font-weight:bold;}
    }
    .arrowIcon {position:absolute; right:.5rem; top:.35rem; cursor:pointer; font-size:16px;}
    }
    .costDiv {font-size:10px; font-weight:bold; color:#000;
        span {font-weight:normal; font-size:8px; display:block;}
    }
}
.greyViewSml {
    .greyViewInner {min-height:140px;
    .titleActivity {font-size:8px;}
    .numDiv {font-size:10px;
    span {font-size:13px;}
    }
}
}
}

.profileSettingDiv {margin-bottom:1rem; margin-top:6rem;
    .profileInner {background:#fff; border-radius:10px; padding:1.5rem; border:1px solid #ccc}
    h3.title {font-size:18px; font-weight:bold; margin-bottom:.5rem; padding-bottom:.5rem; color:#00427E;}
    h5 {font-size:14px; font-weight:bold;}
    .fieldsection {margin-bottom:.5rem;
        .labels {color:#5D5D5D; font-size:14px; font-weight:bold;}
        .textValues {font-weight:bold; color:#000; font-size:12px; margin-bottom:.6rem;}
        .emailID {word-break: break-all;}
    }
    .addText {float:left; width:80%;}
    .mapIcon {float:right; width:20%;}
    .toggleButtons {
        .labels {font-weight:bold; color:#000; font-size:13px; margin-top:.7rem; display:block;}
        .MuiSwitch-track {background:transparent !important; border:1px solid #B4B4B4}
        .MuiSwitch-root {height:40px; width:70px; background:transparent;}
        .MuiSwitch-thumb {background:#B4B4B4; width:30px; border-radius:20px; height:15px; margin:3px 0 0;}

        .MuiSwitch-switchBase.Mui-checked {
            .MuiSwitch-thumb {background:#00427E;}
        }
        .Mui-checked:hover {background:transparent;} 
    }
    .rateDiv {color:#B4B4B4; text-decoration:underline;
        svg {font-size:14px; margin-top:-3px; margin-right:.5rem;}
    }
    .privacyDiv {color:#00427E;text-decoration: underline;}
}

.performanceStatsContainer {width:100%; margin:1rem 0 0 .5rem;
    .rupeeIcon {font-size:14px;}
    .selBx {font-size:14px; margin:2rem .3rem 0 0; 
        .MuiSelect-select, .MuiOutlinedInput-notchedOutline {border:1px solid #ccc; font-weight:bold;  font-size:12px; padding:4px 0 2px 8px;
        }
    }
    h6.MuiTypography-root {font-size:13px; clear:both; font-weight:bold;}
}


.myView.active{
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border: 1px solid #000000 !important;
}
.myView{
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid #CCCCCC !important;
    width: 100%;
    margin-right: 5px !important;
}
.btn-inner-container {float:left;
    h4.MuiTypography-root {width:60%;}
    .MuiButtonBase-root {width:130px; padding:.4rem 0; font-weight:bold; font-size:14px; border-radius:5px; float:left;}
    .MuiButtonBase-root:last-of-type {float:right; margin-left:.5rem;}
}
.button-container {
width:86%; float:left;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1rem;
    p.viewAll {float:left; margin:0; display:none;}
}
.dropdown-container {width:60%; float:left;
    .MuiInput-underline:before {content:none;}
    .selectBx {background:#fff; width:98.5%; text-align:left; padding-left:.5rem; border:1px solid #ccc; border-radius:5px; margin:0 .45rem 0 1rem; font-size:14px;}
    .MuiSelect-root {font-size:16px; font-weight:bold; line-height:22px; padding:.5rem 0;}
}
.myTeamDiv {
    h3.title {font-size:18px; font-weight:bold; margin:0 1rem 0 0; padding-bottom:1rem; color:#000; position:relative;
        span {font-size:10px; color:#5D5D5D; float:right; margin:.5rem 0 0;}
    }
    
}


.modal-sm {
    .modal-content {border-radius:10px !important;}
    .modal-header {border-radius:10px 10px 0 0 !important; color:#00427E; padding:.5rem 1rem;
        .modal-title {font-weight:bold; font-size:1.2rem; box-shadow:none;}
    }
}
.salesManagementDiv {margin:0 0 1rem; border-bottom:1px solid rgba(204, 204, 204, 0.5);
    h5 {font-size:1rem; font-weight:bold;}
    h6 {margin:0 0 .5rem .75rem}
    .totalDiv {
        h6 {font-weight:bold; font-size:1rem; color:#00427E;}
    }
    h6.daysDiv {font-weight:bold; font-size:.9rem;}
}
.salesManagementDiv:last-child {margin-bottom:0; border-bottom:0;}
@media only screen and (max-width:600px) {
    .dashboardDetail {display:inherit;}
    .dashboardDetail .dashboardHead {width:100%;}
    .button-container {width:100%; float:none; margin-top:1rem; margin-bottom:1.75rem; padding:1rem .85rem 0; background:#fff; border:1px solid #ccc; overflow:hidden;}
    .btn-inner-container {margin-bottom:1rem; width:100%;}
    .btn-inner-container .MuiButtonBase-root {width:94%; float:none;}
    .dropdown-container .selectBx {margin:0;}
    .dropdown-container {width:99%;}
}


@media only screen and (max-width:767px) {
   .addtask-container{
    .dynamic-form{
         .form-actions {
        position: relative;
        width: 100%;
        padding: 10px;
        z-index: 0;
    }
}
   }

}
