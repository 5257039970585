.map-container{
    height: 650px;
    width: 1000px;
}

@media only screen and (max-width:767px) {
    body{
        background-color: red;
    }
    .map-container{
        height: 500px;
        width: 600px;
    }
 }